import React from "react";
import { render } from "react-dom";
import NotificationContainer from "react-notify-toast";

import FeedbackModal from "./feedback-modal";
import TourModal from "./tour-modal";
import GoogleTranslateModal from "./google-translate-modal";
import LoaderContainer from "Shared/loader";
import { displayError, CookieUtils } from "Utils";
import { ACTION_TYPES } from "Constants";
import { getContentByUrl } from "Services/umbracoCommonServices";
import { disableTranslateForUser } from "Services/commonServices";

const DISABLE_TOUR_COOKIE = "disableTour";
const DISABLE_TRANSLATE_COOKIE = "disableTranslate";

const APP_CONFIG = window.globalObject || {};

const Feedback = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [showTour, setShowTour] = React.useState(false);
  const [showTranslate, setShowTranslate] = React.useState(false);
  const [loginCheck, setLoginCheck] = React.useState(false);

  const [tourData, setTourData] = React.useState(null);

  const fetchTourDemoData = async () => {
    const { data, error } = await getContentByUrl("/destination-tour/");
    if (error) {
      displayError(error, data);
      return false;
    }
    setTourData(data);
  };

  React.useEffect(() => {
    if (APP_CONFIG.OrderId && !APP_CONFIG.isMyMovePage) {
      fetchTourDemoData();
    }
  }, []);

  React.useEffect(() => {
    const showTranslate = CookieUtils.getCookie(DISABLE_TRANSLATE_COOKIE);
    const firstlogincheck = async () => {
      const { data, error } = await disableTranslateForUser();
      if (error) {
        displayError(error, data);
        return false;
      }
    };
    const translateTrigger = () => {
      document.getElementById("google-translate-modal").style.display = "block";
      setShowTranslate(true);
      let ele = document.getElementsByClassName("goog-te-combo")[0];
      ele?.removeEventListener("change", translateTrigger);
    };
    const translateModelTrigger = () => {
      let ele = document.getElementsByClassName("goog-te-combo")[0];
      if (!showTranslate && ele && !APP_CONFIG.hideTranslate) {
        ele?.addEventListener("change", translateTrigger);
        clearInterval(translateModelInterval);
      } else if (showTranslate && !APP_CONFIG.hideTranslate && loginCheck) {
        firstlogincheck();
        setLoginCheck(false);
      }
    };
    var translateModelInterval = setInterval(translateModelTrigger, 1000);
  }, [setShowTranslate]);

  React.useEffect(() => {
    if (!APP_CONFIG.hideDemo) {
      setShowTour(true);
    }
  }, []);

  const toursDemoTriggerHandler = () => {
    setShowTour(true);
  };

  React.useEffect(() => {
    window.addEventListener(ACTION_TYPES.ToursGuide, toursDemoTriggerHandler);

    return () => {
      window.removeEventListener(
        ACTION_TYPES.ToursGuide,
        toursDemoTriggerHandler
      );
    };
  }, []);

  return (
    <React.Fragment>
      {APP_CONFIG.feedbackEnabled && (
        <React.Fragment>
          <div className="feedback-module" onClick={() => setShowModal(true)}>
            <img src="/Content/images/school/feedback-white.svg" />
            <span>Feedback</span>
          </div>
          <NotificationContainer options={{ zIndex: 999999999 }} />
        </React.Fragment>
      )}
      {showModal && (
        <FeedbackModal show={showModal} onClose={() => setShowModal(false)} />
      )}

      {
        <React.Fragment>
          <div
            id="google-translate-modal"
            className="google-translate-modal"
            style={{ display: "none" }}
          ></div>
          <NotificationContainer options={{ zIndex: 999999999 }} />
        </React.Fragment>
      }
      {showTranslate && (
        <GoogleTranslateModal
          disableTranslateCookie={DISABLE_TRANSLATE_COOKIE}
          show={showTranslate}
          onClose={() => setShowTranslate(false)}
        />
      )}

      {APP_CONFIG.OrderId &&
        !APP_CONFIG.isMyMovePage &&
        showTour &&
        tourData && (
          <TourModal
            show={showTour}
            onClose={() => setShowTour(false)}
            tourData={tourData && tourData}
          />
        )}
      <LoaderContainer />
    </React.Fragment>
  );
};

render(<Feedback />, document.getElementById("feedback_module"));
