import React, { useEffect, useRef } from "react";

export default ({ message = "", position = "top", children }) => {
  const [displayTooltip, setDisplayTooltip] = React.useState(false);
  const [dir, setDir] = React.useState(position);

  const toolTipRef = useRef(null);
  const hideTooltip = () => {
    setDisplayTooltip(false);
  };
  const showTooltip = (e) => {
    setDisplayTooltip(true);
    setTimeout(calculateDir, 0);
  };

  const setPosition = (dir) => {
    if (dir) {
      setDir(dir);
    } else {
      setDir(position);
    }
  };

  useEffect(() => {
    // reset position if window has been resized
    window.addEventListener("resize", () => setPosition());
    return () => {
      window.removeEventListener("resize", () => setPosition());
    };
  }, []);

  const calculateDir = () => {
    if (toolTipRef.current) {
      let bounding = toolTipRef.current.getBoundingClientRect();
      if (bounding.top < 0) {
        // Top is out of viewport
        setPosition("bottom");
      } else if (bounding.left < 0) {
        // Left side is out of viewoprt
        setPosition("right");
      } else if (
        bounding.bottom >
        (window.innerHeight || document.documentElement.clientHeight)
      ) {
        // Bottom is out of viewport
        setPosition("top");
      } else if (
        bounding.right >
        (window.innerWidth || document.documentElement.clientWidth)
      ) {
        // Right side is out of viewport
        setPosition("left");
      }
    }
  };

  return (
    <span className="tooltip" onMouseLeave={hideTooltip}>
      {displayTooltip && (
        <div className={`tooltip-bubble tooltip-${dir}`}>
          <div ref={toolTipRef} className="tooltip-message">
            {message}
          </div>
        </div>
      )}
      <span className="tooltip-trigger notranslate" onMouseOver={showTooltip}>
        {children}
      </span>
    </span>
  );
};
