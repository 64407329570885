import axios from "./axios.config";
import { parseAxiosError } from "Utils";

const getAllBookmarks = async (oderID) => {
  let _response = {};
  try {
    const response = await axios.get(`/api/generalbookmarking/get/${oderID}`);
    _response = { ..._response, data: response.data, error: null };
  } catch (err) {
    _response = {
      ..._response,
      data: err.response && err.response.data,
      error: err,
    };
  }

  return _response;
};

const addBookmark = async (orderID, data) => {
  let _response = {};
  try {
    const response = await axios.post(
      `/api/generalbookmarking/${orderID}`,
      data
    );
    _response = { ..._response, data: response.data, error: null };
  } catch (err) {
    _response = {
      ..._response,
      data: err.response && err.response.data,
      error: err,
      errorLog: parseAxiosError(err),
    };
  }

  return _response;
};

const deleteBookmark = async (bookmarkId) => {
  let _response = {};
  try {
    const response = await axios.delete(
      `/api/generalbookmarking/delete/${bookmarkId}`
    );
    _response = { ..._response, data: response.data, error: null };
  } catch (err) {
    _response = {
      ..._response,
      data: err.response && err.response.data,
      error: err,
    };
  }

  return _response;
};

const getComment = async (orderID, type, metroId) => {
  let _response = {};
  try {
    const response = await axios.get(
      `/api/comment/get/${orderID}/${type}?metroareacode=${metroId}`
    );
    _response = { ..._response, data: response.data, error: null };
  } catch (err) {
    _response = {
      ..._response,
      data: err.response && err.response.data,
      error: err,
    };
  }

  return _response;
};

const addComment = async (data) => {
  let _response = {};
  try {
    const response = await axios.post(`/api/comment/insert`, data);
    _response = { ..._response, data: response.data, error: null };
  } catch (err) {
    _response = {
      ..._response,
      data: err.response && err.response.data,
      error: err,
    };
  }

  return _response;
};

const editComment = async (orderId, commentId, data) => {
  let _response = {};
  try {
    const response = await axios.put(
      `/api/comment/update/${orderId}/${commentId}`,
      data
    );
    _response = { ..._response, data: response.data, error: null };
  } catch (err) {
    _response = {
      ..._response,
      data: err.response && err.response.data,
      error: err,
    };
  }

  return _response;
};

const deleteComment = async (orderId, commentId) => {
  let _response = {};
  try {
    const response = await axios.delete(
      `/api/comment/delete/${orderId}/${commentId}`
    );
    _response = { ..._response, data: response.data, error: null };
  } catch (err) {
    _response = {
      ..._response,
      data: err.response && err.response.data,
      error: err,
    };
  }

  return _response;
};

const submitUserFeedback = async (data) => {
  let _response = {};
  try {
    const response = await axios.post("/api/feedback/submituserfeedback", data);
    _response = { ..._response, data: response.data, error: null };
  } catch (err) {
    _response = {
      ..._response,
      data: err.response && err.response.data,
      error: err,
    };
  }

  return _response;
};

const submitPulseCheckFeedback = async (data, orderId) => {
  let _response = {};
  try {
    const response = await axios.post(
      `/api/pulsecheck/submitpulsecheckfeedback/${orderId}`,
      data
    );
    _response = { ..._response, data: response.data, error: null };
  } catch (err) {
    _response = {
      ..._response,
      data: err.response && err.response.data,
      error: err,
    };
  }

  return _response;
};

const disableTourForUser = async () => {
  let _response = {};
  try {
    const response = await axios.post("/api/users/hide-user-demo/", {
      HideDemo: true,
    });
    _response = { ..._response, data: response.data, error: null };
  } catch (err) {
    _response = {
      ..._response,
      data: err.response && err.response.data,
      error: err,
    };
  }

  return _response;
};

const disableTranslateForUser = async () => {
  let _response = {};
  try {
    const response = await axios.post("/api/users/hide-user-translate/", {
      HideTranslate: true,
    });
    _response = { ..._response, data: response.data, error: null };
  } catch (err) {
    _response = {
      ..._response,
      data: err.response && err.response.data,
      error: err,
    };
  }

  return _response;
};

const submitUserQuestion = async (data) => {
  let _response = {};
  try {
    const response = await axios.post("/api/faq/submitquestion", data);
    _response = { ..._response, data: response.data, error: null };
  } catch (err) {
    _response = {
      ..._response,
      data: err.response && err.response.data,
      error: err,
    };
  }

  return _response;
};

const downloadFile = async (fileUrl) => {
  try {
    const response = await axios.get(fileUrl, { responseType: "arraybuffer" });
    return response?.data;
  } catch (e) {
    return false;
  }
};
const fetchOrderById = async (orderNo) => {
  let _response = {};
  try {
    const response = await axios.get(`/api/admin/GetOrdervalues/${orderNo}`);
    _response = { ..._response, data: response.data, error: null };
  } catch (err) {
    _response = {
      ..._response,
      data: err.response && err.response.data,
      error: err,
    };
  }
  return _response;
};
const setTransferee = async (Email, orderId) => {
  try {
    const response = await axios.post(`api/admin/SetNewTransferee/${orderId}`, {
      Email: Email,
    });
    return { data: response?.data, error: null };
  } catch (err) {
    return {
      data: null,
      error: err.message,
    };
  }
};

const fetchLocaleData = async ({ endpoint, culture }, done, fail) => {
  let _response = {};
  try {
    const response = await axios.get(`/api/${endpoint}/content/${culture}`);
    done({ ..._response, data: response.data, error: null });
  } catch (err) {
    fail({
      ..._response,
      data: err.response && err.response.data,
      error: err,
    });
  }

  return _response;
};

const getPulseCheckFlag = async ({ orderId }) => {
  let _response = {};
  try {
    const response = await axios.get(
      `/api/pulsecheck/showpulsecheckdialogbox/${orderId}`,
      { timeout: 5000 }
    );
    _response = { ..._response, data: response.data, error: null };
  } catch (err) {
    _response = {
      ..._response,
      data: false,
      error: err,
    };
  }

  return _response;
};

const getRentalMarketPdf = async (data) => {
  let _response = {};
  try {
    const response = await axios.post(
      `/Orders/GenerateRentalMarketOverViewPDF`,
      {
        ...data,
      }
    );
    _response = { ..._response, data: response.data, error: null };
  } catch (err) {
    _response = {
      ..._response,
      data: null,
      error: err,
    };
  }

  return _response;
};

export {
  getAllBookmarks,
  addBookmark,
  deleteBookmark,
  disableTourForUser,
  disableTranslateForUser,
  getComment,
  addComment,
  editComment,
  deleteComment,
  submitUserFeedback,
  submitUserQuestion,
  downloadFile,
  fetchLocaleData,
  submitPulseCheckFeedback,
  getPulseCheckFlag,
  fetchOrderById,
  setTransferee,
  getRentalMarketPdf,
};
