import React from "react";
import ReactDOM from "react-dom";
import clsx from "clsx";

const WRAPPER_ID = "loader-wrapper";
let activeLoaderCount = 0;

class LoaderObservable {
  static $loader = new LoaderObservable();
  constructor() {
    this.loading = false;
    this.callbacks = [];
    this.activeLoaderCount = 0;
  }
  subscribe(callback) {
    return this.callbacks.push(callback);
  }
  unsubscribe(idx) {
    if (!isNaN(idx)) {
      this.callbacks.splice(idx - 1, 1);
    }
  }
  get isLoading() {
    return this.loading;
  }
  set loaderState(x) {
    this.loading = x;
    for (let i = 0; i < this.callbacks.length; i++) {
      let cb = this.callbacks[i];
      cb && cb(this.isLoading);
    }
  }
}

window.$loader = window.$loader || LoaderObservable.$loader;
const Loader = ({ show }) => {
  return (
    <div
      id="appLoader"
      className={clsx("loaderOverlay react_loader", {
        "fade-in": show,
        "fade-out": !show,
      })}
      style={{ display: "block" }}
    >
      <div className="sk-folding-cube">
        <div className="sk-cube1 sk-cube"></div>
        <div className="sk-cube2 sk-cube"></div>
        <div className="sk-cube4 sk-cube"></div>
        <div className="sk-cube3 sk-cube"></div>
      </div>
    </div>
  );
};

function renderLoader() {
  const overlayTarget = document.getElementById(WRAPPER_ID);
  ReactDOM.render(<Loader show={true} />, overlayTarget);
}

function hide(forceClose = false) {
  activeLoaderCount--;
  const { $loader } = window;
  if ($loader.activeLoaderCount > 0) {
    $loader.activeLoaderCount--;
  }
  if ($loader.activeLoaderCount <= 0) {
    $loader.loaderState = false;
  }
  if (activeLoaderCount > 0 && !forceClose) return;
  const overlayTarget = document.getElementById(WRAPPER_ID);
  ReactDOM.render(<Loader show={false} />, overlayTarget);
  // Added SetTimeOut for fadeOut animation to complete
  setTimeout(() => ReactDOM.unmountComponentAtNode(overlayTarget), 700);
  return true;
}

function show() {
  activeLoaderCount++;
  const { $loader } = window;
  $loader.activeLoaderCount++;
  $loader.loaderState = true;
  if (!document.getElementById(WRAPPER_ID).hasChildNodes()) {
    renderLoader();
    return true;
  }

  return false;
}

export const loader = {
  show,
  hide,
};

export default () => {
  return <div id={WRAPPER_ID} />;
};
