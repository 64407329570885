import React from "react";
import Modal from "react-modal";
import clsx from "clsx";

const noop = () => {};

export default ({
  isOpen = false,
  onClose = noop,
  children,
  wrapperClassName,
  ...props
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      portalClassName={clsx(["react-custom-modal", wrapperClassName])}
      bodyOpenClassName="no-scroll"
      ariaHideApp={false}
      {...props}
    >
      {children}
    </Modal>
  );
};
