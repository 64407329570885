import React from "react";
import { notify } from "react-notify-toast";
import { disableTranslateForUser } from "Services/commonServices";

import Modal from "Shared/Modal";
import { CookieUtils, displayError } from "Utils";

const APP_CONFIG = window.globalObject || {};

export default ({ show, onClose, disableTranslateCookie }) => {
  const [doNotShowTranslate, setDoNotShowTranslate] = React.useState(false);

  const handleFeedbackOk = async () => {
    if (!doNotShowTranslate && CookieUtils.getCookie(disableTranslateCookie)) {
      const { data, error } = await disableTranslateForUser();
      if (error) {
        displayError(error, data);
        return false;
      }
    }
    setDoNotShowTranslate(!doNotShowTranslate);
    document.getElementById("google-translate-modal").style.display = "none";
    CookieUtils.setCookie(disableTranslateCookie, true, 1);

    onClose();
  };

  return (
    <Modal
      isOpen={show}
      onClose={() => {}}
      wrapperClassName="google-translate-modal"
    >
      <div>
        <div className="header">
          <div className="dw-logo">
            <img
              src="/Content/Images/dwellworks_icon.png"
              className="c-primaryNav__logo"
            />
          </div>
          <div
            className="close-btn"
            onClick={() => {
              handleFeedbackOk();
            }}
          >
            <img
              style={{ height: 24 }}
              src="/Content/images/my-resources/icon_close.svg"
            />
          </div>
        </div>
        <div className="modal-scrollable">
          <div className="content">
            <p className="help-text">
              Dwellworks provides the <b>"Google Translate"</b> option to help
              you read our content in languages other than English. Google
              Translate cannot translate all types of documents and may not
              provide an exact translation. Anyone relying on information
              obtained from Google Translate does so at their own risk.
              Dwellworks does not control the quality or accuracy of translated
              content and does not make any promises, assurances, or guarantees
              as to the accuracy of the translations provided.
            </p>

            <div>
              <div>
                <p className="what-type-feedback">
                  If you have any questions about Google Translate, please visit
                  the &nbsp;
                  <a href="https://translate.google.com/">
                    Google Translate website
                  </a>
                  .
                </p>

                <p className="what-type-feedback">
                  If you have any issues with Google Translate, please
                  visit&nbsp;
                  <a href="http://translate.google.com/support/">
                    http://translate.google.com/support/
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="footer-btns">
            <button className="save-btn" onClick={handleFeedbackOk}>
              OK
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
