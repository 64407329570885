import { notify } from "react-notify-toast";
import { detect } from "detect-browser";
import FileSaver from "file-saver";
import { Blob } from "blob-polyfill";

const browser = detect();

const displayError = (...args) => {
  const defaultErrorMessage = "Something went wrong, please try again.";
  let errorMessage;

  args.map((error) => {
    if (error && !errorMessage) {
      errorMessage = error.Error || error;
      if (typeof errorMessage === "object") {
        errorMessage = errorMessage.Title || errorMessage.message;
      }
    }
  });

  notify.show(errorMessage || defaultErrorMessage, "error");
};

const slugify = (text, separator = "-") => {
  return (
    text &&
    text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, separator)
      .replace(/[^\w-]+/g, "")
      .replace(/--+/g, separator)
      .replace(/^-+/, "")
      .replace(/-+$/, "")
  );
};

const parseBookmarkData = (rawData) => {
  if (!rawData) return {};

  try {
    return rawData.reduce((updatedData, item) => {
      const type = item.Type;
      const dataToAdd = {
        rowId: item.Id,
        id: item.ItemId,
        type,
        meta: item.Information && JSON.parse(item.Information),
        title: item.Title,
        messages: item.ResourceMessages,
      };
      return updatedData[type]
        ? { ...updatedData, [type]: [...updatedData[type], dataToAdd] }
        : { ...updatedData, [type]: [dataToAdd] };
    }, {});
  } catch (error) {
    console.warn(error.message);
    return {};
  }
};

const serializeQueryString = function (obj, prefix) {
  let str = [],
    p;
  for (p in obj) {
    if (Object.hasOwnProperty.call(obj, p)) {
      let k = prefix ? prefix + "[" + p + "]" : p;
      let v = obj[p];
      if (v || typeof v === "boolean") {
        str.push(
          v !== null && typeof v === "object"
            ? serializeQueryString(v, k)
            : encodeURIComponent(k) + "=" + encodeURIComponent(v)
        );
      }
    }
  }
  return str.join("&");
};

const formatFaqData = (data, renderer) => {
  if (!data || data.length === 0) return [];
  const faq = data.map((item, index) => {
    const hasChildren = item.accInnerLevel && item.accInnerLevel.length > 0;

    return {
      id: `glance_${index}`,
      title: item.accTitle,
      content: item.accContent,
      renderer,
      children: !hasChildren
        ? null
        : item.accInnerLevel.map((child) => ({
            title: child.accTitle,
            content: renderer ? renderer(child) : child.accContent,
          })),
    };
  });

  return (faq && faq.filter((item) => item)) || [];
};

const getUnreadCount = (msgList, currentUserId, isPmUser) => {
  if (!msgList || msgList.length === 0) return false;

  const unreadMessages = msgList.filter((item) => {
    if (!isPmUser)
      return !item.IsRecipientRead && currentUserId !== item.AuthorId;
    else return !item.IsPMRead;
  });

  return unreadMessages.length > 0 ? unreadMessages.length : false;
};

const getOffset = (element, horizontal = false) => {
  if (!element) return 0;
  return (
    getOffset(element.offsetParent, horizontal) +
    (horizontal ? element.offsetLeft : element.offsetTop)
  );
};

const isInternetExplorer = () => {
  const userAgent = window.navigator.userAgent;
  return /MSIE|Trident/.test(userAgent);
};

const extractContent = (html, excerptLength = false) => {
  const domParser = new DOMParser().parseFromString(html, "text/html");
  let text = domParser.documentElement.textContent;

  if (excerptLength) {
    const excerptedWords = text.split(" ").slice(0, excerptLength);
    return text.split(" ").length > excerptedWords.length
      ? excerptedWords.join(" ") + "..."
      : excerptedWords.join(" ");
  }
  return text;
};

const parseQueryParams = (params = "") => {
  const rawParams = params.replace("?", "").split("&");
  const extractedParams = {};
  rawParams.forEach((item) => {
    item = item.split("=");
    extractedParams[item[0]] = item[1];
  });
  return extractedParams;
};

const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};
const getCookie = (name) => {
  let nameEQ = name + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};
const eraseCookie = (name) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

const CookieUtils = {
  setCookie,
  getCookie,
  eraseCookie,
};

const triggerEvent = (element, event) => {
  if (document.createEventObject) {
    // dispatch for IE
    const evt = document.createEventObject();
    return element.fireEvent("on" + event, evt);
  } else {
    const evt = document.createEvent("HTMLEvents");
    evt.initEvent(event, true, true); // event type,bubbling,cancelable
    return !element.dispatchEvent(evt);
  }
};

const uuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const formatCurrency = (culture, amount, decimalCount = null) => {
  try {
    if (decimalCount == null) decimalCount = 0;

    let symbol = culture.CurrencySymbol;
    let decimal = culture.NumberGroupSeparator;
    let thousands = culture.NumberGroupSeparator;

    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    let negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      symbol +
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
};

const isValidData = (data) => {
  if (!data && typeof data !== "boolean") return false;
  if (typeof data === "boolean") return true;

  if (typeof data === "object" && !Array.isArray(data)) {
    return !!Object.keys(data).length;
  } else {
    return data?.trim ? !!data.trim() : !!data.length;
  }
};

const parseJson = (str) => {
  try {
    return JSON.parse(str);
  } catch (error) {
    return false;
  }
};

const prefixUrl = (url) => {
  if (url && !/^https?:\/\//g.test(url.trim())) {
    url = "//" + url.trim();
  }
  return url;
};

const getBrowserInfo = () => {
  let browserInfo = {
    browserName: browser.name,
    browserVersion: browser.version,
    machineInfo: browser.os,
  };
  return browserInfo;
};

const parseAxiosError = (error) => {
  let browserInfo = getBrowserInfo();
  let err = { ...browserInfo };
  err.stack = error?.stack;
  let responseMsg = "API request failed to get/update/delete/add.";
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // console.log(error.response.data);
    err.status = error.response.status;
    err.url = error.response.config.url;
    let data = parseJson(error.response.config.data);
    err.data = data ? data : null;
    err.error = error.response.data;
    err.message = error.response.statusText || responseMsg;
    return err;
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the
    // browser and an instance of
    // http.ClientRequest in node.js
    err.status = error.request.status;
    err.url = error.request.responseURL;
    err.message = error.request.statusText || responseMsg;
    return err;
  } else {
    // Something happened in setting up the request that triggered an Error
    err.message = error.message || responseMsg;
    return err;
  }
};

const getJson = (obj) => {
  try {
    let json = Function(`"use strict";return (${obj})`)();
    if (
      Object.prototype.toString.call(json.coordinates) === "[object String]"
    ) {
      json.coordinates = Function(
        `"use strict";return (${json.coordinates})`
      )();
    }
    return json;
  } catch (err) {
    console.log(err);
    return null;
  }
};

const toBytesArray = (arr) => {
  let binaryLen = arr.length;
  let bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = arr[i];
  }
  return bytes;
};

const saveBytesArrayAsPdf = (byte, fileName) => {
  let blob = new Blob([byte], { type: "application/pdf" });
  FileSaver.saveAs(blob, fileName);
};

const getCamelCaseString = (str) => {
  if (!str) return "";
  return str
    .replaceAll("_", " ")
    .split(" ")
    .filter(Boolean)
    .map(
      (str) => str[0].toUpperCase() + str.substr(1, str.length).toLowerCase()
    )
    .join(" ");
};

export {
  slugify,
  displayError,
  parseBookmarkData,
  serializeQueryString,
  formatFaqData,
  getUnreadCount,
  getOffset,
  isInternetExplorer,
  extractContent,
  parseQueryParams,
  CookieUtils,
  triggerEvent,
  uuid,
  formatCurrency,
  isValidData,
  parseJson,
  prefixUrl,
  parseAxiosError,
  getBrowserInfo,
  getJson,
  toBytesArray,
  saveBytesArrayAsPdf,
  getCamelCaseString,
};
