import React from "react";
import Modal from "Shared/Modal";
import Tooltip from "Shared/tooltip";
import { disableTourForUser } from "Services/commonServices";
import { displayError } from "Utils";

const APP_CONFIG = window.globalObject || {};

export default ({ show, onClose, tourData = {} }) => {
  const [step, setStep] = React.useState(0);
  const [doNotShowTour, setDoNotShowTour] = React.useState(false);

  const getTemplateData = () => {
    let temp = [];
    if (APP_CONFIG.isMVPCity) {
      temp = tourData.templateRepeatable.filter((_data) =>
        _data.categoryIncludes.includes("Metro")
      );
    } else if (APP_CONFIG.isMVPState) {
      temp = tourData.templateRepeatable.filter((_data) =>
        _data.categoryIncludes.includes("State")
      );
    } else {
      temp = tourData.templateRepeatable.filter((_data) =>
        _data.categoryIncludes.includes("Legacy")
      );
    }
    return temp;
  };

  const templateData = getTemplateData();
  const contentToRender = templateData[step];

  const handleCloseClick = async () => {
    if (doNotShowTour) {
      const { data, error } = await disableTourForUser();
      if (error) {
        displayError(error, data);
        return false;
      }
    }

    onClose();
  };

  return (
    <Modal isOpen={show} onClose={() => {}} wrapperClassName="tour-modal">
      <div>
        <div className="modal-scrollable">
          <div className="content">
            <div className="left-section">
              {templateData.map((m, index) => (
                <div
                  className={`menu-item ${step === index ? "selected" : ""}`}
                  key={m.tourTemplateHeading}
                >
                  <span onClick={() => setStep(index)}>
                    {m.tourTemplateHeading}
                  </span>
                </div>
              ))}
            </div>
            <div className="right-content">
              <div className="header">
                <div className="close-btn" onClick={handleCloseClick}>
                  <img
                    style={{ height: 20 }}
                    src="/Content/images/my-resources/icon_close.svg"
                  />
                </div>
              </div>
              {step === 0 ? (
                <WelcomeTemplate content={contentToRender} />
              ) : (
                <OtherTemplate content={contentToRender} />
              )}
              <div className="nav-wrapper">
                {step !== 0 && (
                  <div
                    className="nav-left"
                    onClick={() => setStep(step - 1)}
                  ></div>
                )}
                {step < templateData.length - 1 && (
                  <div
                    className="nav-right"
                    onClick={() => setStep(step + 1)}
                  ></div>
                )}
              </div>
              <div className="dont-show-tour">
                <input
                  type="checkbox"
                  checked={doNotShowTour}
                  onChange={() => setDoNotShowTour(!doNotShowTour)}
                />
                &nbsp;&nbsp;
                <label>
                  {tourData.dontShowText}
                  <Tooltip
                    message="You can access this site tour from Help Center anytime."
                    position={"top"}
                  >
                    {" "}
                    <span>i</span>
                  </Tooltip>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const WelcomeTemplate = ({ content }) => {
  let contentToRender =
    content.tourTemplateContent.indexOf("%USERNAME%") > -1
      ? content.tourTemplateContent.replace(
          "%USERNAME%",
          APP_CONFIG.loggedInUserFirstName
        )
      : content.tourTemplateContent;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "5% 0 15%",
      }}
    >
      <div>
        <div dangerouslySetInnerHTML={{ __html: contentToRender }}></div>
      </div>
    </div>
  );
};

const OtherTemplate = ({ content }) => {
  return (
    <div className="other-template">
      <div className="flex-top">
        {content.tourTemplateIcon !== null ? (
          <img src={content.tourTemplateIcon._url} />
        ) : (
          ""
        )}
        <div
          className="w-100"
          dangerouslySetInnerHTML={{ __html: content.tourTemplateContent }}
        ></div>
      </div>
      {content.tourTemplateImage && (
        <div>
          <img
            src={content.tourTemplateImage._url}
            alt=""
            key={content.tourTemplateHeading}
          />
          <div
            className="tour-captions"
            dangerouslySetInnerHTML={{
              __html: content.tourTemplateImageCaption,
            }}
          ></div>
        </div>
      )}
    </div>
  );
};
