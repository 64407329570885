export const DISCOVER_DEFAULTS = {
  CITY_CARD_THUMBNAIL: "/Content/images/discover/city-thumbnail.jpg",
  DEFAULT_HEADER_BG: {
    web: "/Content/images/discover/page-hero-bg.png",
    mobile: "/Content/images/discover/page-hero-bg-mobile.png",
  },
  SECONDARY_HEADER_BG: {
    web: "/Content/images/discover/page-secondary-bg.png",
    mobile: "/Content/images/discover/page-hero-bg-mobile.png",
  },
  INNER_PAGE_BG: {
    web: "/Content/images/discover/inner-page-bg.jpg",
  },
  LANDING_HEADER_BG: {
    web: "/Content/images/discover/discover-hero-bg.png",
    mobile: "/Content/images/discover/discover-hero-bg-mobile.png",
  },
  BEST_OF_HEADER_BG: {
    web: "/Content/images/discover/best-of-hero-bg.png",
    mobile: "/Content/images/discover/best-of-hero-bg-mobile.png",
  },
  GALLERY_OF_HEADER_BG: {
    web: "/Content/images/discover/gallery-hero-bg.png",
  },
  SCHOOL_HEADER_BG: {
    web: "/Content/images/discover/school-hero-bg.jpg",
    mobile: "/Content/images/discover/school-hero-bg-mobile.png",
  },
  HOW_TO_BG: {
    web: "/Content/images/discover/how-to-bg.png",
    mobile: "/Content/images/discover/how-to-bg-mobile.png",
  },
  HELPFUL_INFORMATION_BG: {
    web: "/Content/images/discover/helpful-information-bg.png",
    mobile: "/Content/images/discover/helpful-information-bg-mobile.png",
  },
  LOCAL_DIRECTORIES_BG: {
    web: "/Content/images/discover/local-directories-bg.png",
    mobile: "/Content/images/discover/local-directories-bg-mobile.png",
  },
  MY_RESOURCE_BG: {
    web: "/Content/images/my-resources/my-resource-hero-bg.png",
    mobile: "/Content/images/discover/best-of-hero-bg-mobile.png",
  },
};

export const RESOURCE_TYPE = {
  HOW_TO_GUIDE: "howToGuides",
  LOCAL_DIRECTORY: "localDirectories",
  HELPFUL_INFORMATION: "helpfulInformation",
};

export const RESOURCE_CATEGORY = {
  "A How to Guide": "how-to-guides",
  "Local Directory": "local-directories",
  "Helpful Information": "helpful-information",
};

export const PAGE_URL = {
  DISCOVER: "/Orders/Explore/",
  MY_RESOURCES: {
    howToGuides: "/how-to-guides",
    localDirectories: "/local-directories",
    helpfulInformation: "/helpful-information",
  },
};

export const SUPPORTED_DOCUMENT_TYPES = [
  "pdf",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "ppt",
  "pptx",
  "csv",
  "png",
  "jpg",
  "jpeg",
  "txt",
];

export const FILE_TYPE_ICONS = {
  pdf: "/Content/images/my-resources/icon_export_pdf.svg",
  doc: "/Content/images/my-resources/icon_export_word.svg",
  docx: "/Content/images/my-resources/icon_export_word.svg",
  xls: "/Content/images/my-resources/icon_export_excel.svg",
  xlsx: "/Content/images/my-resources/icon_export_excel.svg",
  png: "/Content/images/my-resources/icon_export_image.svg",
  jpg: "/Content/images/my-resources/icon_export_image.svg",
  jpeg: "/Content/images/my-resources/icon_export_image.svg",
  csv: "/Content/images/my-resources/icon_export_csv.svg",
  txt: "/Content/images/my-resources/icon_export_txt.svg",
  ppt: "/Content/images/my-resources/icon_export_powerpoint.svg",
  pptx: "/Content/images/my-resources/icon_export_powerpoint.svg",
};

export const FILE_EXTENSION_MAP = {
  pdf: "PDF",
  doc: "Word File",
  docx: "Word File",
  xls: "Excel File",
  xlsx: "Excel File",
  png: "Image",
  jpg: "Image",
  jpeg: "Image",
  csv: "CSV File",
  txt: "Manual Input",
  ppt: "Power Point File",
  pptx: "Power Point File",
};

export const ERROR_MESSAGE = {
  error: "Some error occured.",
};

export const Email_Validation_Regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const URL_REGEX = /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;

export const DATE_FORMAT = "YYYY-MM-DD h:mm:ss A";

export const ACTION_TYPES = {
  ToursGuide: "ToursGuide",
};

export const LOCALE_ENDPOINTS = {
  SCHOOL_FINDINGS: "schoolfindings",
};

export const COMMENT_ENUM_MAPPING = {
  socialSecurity: "MyResourceSocialSecurity",
  utilities: "MyResourceUtilities",
  banking: "MyResourceBanking",
  driversLicense: "MyResourceDriverLicense",
  registeringVehicle: "VehicleRegistration",
  taxID: "TaxId",
  healthInsurance: "HealthInsurance",
  residencyPermit: "ResidencyPermit",
  childAllowance: "ChildAllowance",
  declarationArrival: "DeclarationArrival",
};

export const BOOKMARK_ENUM_MAPPING = {
  socialSecurity: "SOCIAL_SECURITY_ITEM",
  utilities: "UTILITY_SERVICE_ITEM",
  banking: "BANKING_SERVICE_ITEM",
  driversLicense: "DRIVERS_LICENSE_ITEM",
  registeringVehicle: "REGISTERING_VEHICLE_ITEM",
  taxID: "TAX_ID_ITEM",
  healthInsurance: "HEALTH_INSURANCE_ITEM",
  residencyPermit: "RESIDENCY_PERMIT_ITEM",
  childAllowance: "CHILD_ALLOWANCE_ITEM",
  declarationArrival: "DECLARATION_ARRIVAL_ITEM",
};

export const CONTACT_TRACK_TYPES = {
  Website: 0,
  Phone: 1,
  InternationalAutoSource: 2,
};
