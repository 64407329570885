import { umbracoApi } from "./axios.config";
import { parseAxiosError } from "Utils";

const filterContent = async (contentTypeAlias, filters = [], pageSize = 1) => {
  let _response = {};
  try {
    const { data, error } = await umbracoApi.post(
      `/filter?hyperlinks=false&pageSize=${pageSize}`,
      {
        contentTypeAlias,
        properties: filters,
      }
    );

    _response = {
      ..._response,
      data,
      error,
    };
  } catch (err) {
    const response = (err.response && err.response.data) || {};
    _response = {
      ..._response,
      data: response.data,
      error: response.error || err,
      errorLog: parseAxiosError(err),
    };
  }

  return _response;
};

const getContentByUrl = async (uri) => {
  let _response = {};
  try {
    const { data, error } = await umbracoApi.get(`/url?url=${uri}`);
    _response = {
      ..._response,
      data,
      error,
    };
  } catch (err) {
    const response = (err.response && err.response.data) || {};
    _response = {
      ..._response,
      data: response.data,
      error: response.error || err,
    };
  }

  return _response;
};

const getChildrenById = async (id, pageSize = 50, pageNo) => {
  let _response = {};
  let uri = `${id}/children?hyperlinks=false`;
  try {
    if (pageSize !== -1) {
      const { data } = await umbracoApi.get(
        `${uri}&pageSize=${pageSize}${pageNo ? "&page=" + pageNo : ""}`
      );
      _response = { ..._response, data };
    } else {
      const { data } = await umbracoApi.get(`${uri}&pageSize=${50}`);

      // To fetch all children from umbraco without pagination
      if (data && data._totalPages > 1) {
        const { data: response } = await umbracoApi.get(
          `${uri}&pageSize=${data._totalItems}`
        );
        return { ..._response, data: response };
      }

      return { ..._response, data };
    }
  } catch (err) {
    const response = (err.response && err.response.data) || {};
    _response = {
      ..._response,
      data: response.data,
      error: response.error || err,
      errorLog: parseAxiosError(err),
    };
  }

  return _response;
};

const getDescendantsById = async (id, contentType, pageSize = 50) => {
  let _response = {};
  try {
    let uri = `${id}/descendants`;
    uri += `?pageSize=${pageSize}`;
    uri += contentType ? `&contentType=${contentType}` : "";
    const { data, error } = await umbracoApi.get(uri);
    _response = {
      ..._response,
      data,
      error,
    };
  } catch (err) {
    const response = (err.response && err.response.data) || {};
    _response = {
      ..._response,
      data: response.data,
      error: response.error || err,
      errorLog: parseAxiosError(err),
    };
  }

  return _response;
};

const getContentById = async (id) => {
  let _response = {};
  try {
    const { data, error } = await umbracoApi.get(`${id}`);
    _response = {
      ..._response,
      data,
      error,
    };
  } catch (err) {
    const response = (err.response && err.response.data) || {};
    _response = {
      ..._response,
      data: response.data,
      error: response.error || err,
    };
  }

  return _response;
};

export {
  getChildrenById,
  getContentById,
  getDescendantsById,
  getContentByUrl,
  filterContent,
};
