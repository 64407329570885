import React from "react";
import { notify } from "react-notify-toast";

import Modal from "Shared/Modal";
import { Email_Validation_Regex, ERROR_MESSAGE } from "Constants";
import { loader } from "Shared/loader";
import { submitUserFeedback } from "Services/commonServices";

const APP_CONFIG = window.globalObject || {};
const FEEDBACK_TYPE_OTHER = 4;
const initialFeedbackObj = {
  Rating: null,
  AdditionalInformation: "",
  ContactEmail: "",
  ContactPhoneNumber: "",
  FeedbackType: null,
  OrderId: APP_CONFIG.OrderId || null,
  PageName: window.___PAGE_NAME || "",
};

export default ({ show, onClose }) => {
  const [canContact, setCanContact] = React.useState(null);
  const [feedbackObj, setFeedbackObj] = React.useState(initialFeedbackObj);
  const [contactMethod, setContactMethod] = React.useState({
    ContactEmail: false,
    ContactPhoneNumber: false,
  });
  const [error, setError] = React.useState({
    ContactEmail: false,
    ContactPhoneNumber: false,
  });

  const handleFeedbackSubmit = async () => {
    if (!contactMethod.ContactEmail) {
      delete feedbackObj.ContactEmail;
    }
    if (!contactMethod.ContactPhoneNumber) {
      delete feedbackObj.ContactPhoneNumber;
    }
    loader.show();
    const { error } = await submitUserFeedback({
      ...feedbackObj,
      PageName: window.___PAGE_NAME,
    });
    loader.hide();
    if (error) {
      notify.show(ERROR_MESSAGE["error"], "error");
    } else {
      onClose();
      notify.show(
        <span className="success-toast-message">
          Feedback submitted successfully
        </span>,
        "success"
      );
    }
  };

  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    if (name === "Rating" || name === "FeedbackType") {
      const newObj = { ...feedbackObj, Rating: null };
      setFeedbackObj({ ...newObj, [name]: parseInt(value) });
    } else {
      if (name === "ContactEmail" || name === "ContactPhoneNumber") {
        validateFields(name, value);
      }
      setFeedbackObj({ ...feedbackObj, [name]: value });
    }
    if (
      name !== "AdditionalInformation" &&
      name !== "ContactEmail" &&
      name !== "ContactPhoneNumber"
    ) {
      try {
        document
          .getElementById(":1.container")
          ?.contentWindow.document.getElementById(":1.confirm")
          .click();
      } catch (e) {
        return;
      }
    }
  };

  const validateFields = (name, value) => {
    if (name === "ContactEmail") {
      !Email_Validation_Regex.test(value)
        ? setError({ ...error, ContactEmail: true })
        : setError({ ...error, ContactEmail: false });
    } else {
      value.length > 32
        ? setError({ ...error, ContactPhoneNumber: true })
        : setError({ ...error, ContactPhoneNumber: false });
    }
  };

  const handleCanContactChange = (event) => {
    setCanContact(parseInt(event.target.value));

    try {
      document
        .getElementById(":1.container")
        ?.contentWindow.document.getElementById(":1.confirm")
        .click();
    } catch (e) {
      return;
    }
  };

  const handleContactMethodChange = (event) => {
    const { name, checked } = event.target;
    setContactMethod({ ...contactMethod, [name]: checked });
  };

  const preventSubmit =
    !feedbackObj.FeedbackType ||
    (feedbackObj.FeedbackType !== FEEDBACK_TYPE_OTHER && !feedbackObj.Rating) ||
    feedbackObj.AdditionalInformation.length > 500 ||
    (feedbackObj.FeedbackType === FEEDBACK_TYPE_OTHER &&
      !feedbackObj.AdditionalInformation.trim()) ||
    (contactMethod.ContactEmail &&
      (error.ContactEmail || !feedbackObj.ContactEmail.trim())) ||
    (contactMethod.ContactPhoneNumber &&
      (error.ContactPhoneNumber || !feedbackObj.ContactPhoneNumber.trim()));

  return (
    <Modal
      isOpen={show}
      onClose={() => {
        onClose();
      }}
      wrapperClassName="feedback-modal"
    >
      <div>
        <div className="header">
          <div className="dw-logo">
            <img
              src="/Content/Images/dwellworks_icon.png"
              className="c-primaryNav__logo"
            />
          </div>
          <div
            className="close-btn"
            onClick={() => {
              onClose();
            }}
          >
            <img
              style={{ height: 20 }}
              src="/Content/images/my-resources/icon_close.svg"
            />
          </div>
        </div>
        <div className="modal-scrollable">
          <div className="content">
            <p className="help-text">
              We appreciate your feedback! Your opinions will help us improve
              our site and our service.
            </p>
            {APP_CONFIG.isTransferee && (
              <p className="help-text">
                If you need service assistance, use the contact information
                provided in the left navigation to reach your Consultant or
                Program Manager.
              </p>
            )}
            {!APP_CONFIG.isTransferee && (
              <p className="help-text">
                If you need assistance, please contact your Program Manager.
              </p>
            )}

            <div>
              <p className="what-type-feedback">
                What type of feedback would you like to provide?
              </p>
              {APP_CONFIG.feedbackTypes &&
                APP_CONFIG.feedbackTypes.map((option) => (
                  <label
                    key={option.Value}
                    htmlFor={option.Id}
                    className="container feedback-options"
                  >
                    <input
                      type="radio"
                      id={option.Id}
                      name="FeedbackType"
                      value={option.Value}
                      checked={feedbackObj.FeedbackType == option.Value}
                      onChange={handleFieldChange}
                    />
                    {option.Text}
                    <span className="checkmark"></span>
                  </label>
                ))}
            </div>
            {feedbackObj.FeedbackType &&
              feedbackObj.FeedbackType !== FEEDBACK_TYPE_OTHER && (
                <RatingScale
                  feedbackObj={feedbackObj}
                  onChange={handleFieldChange}
                />
              )}
            {feedbackObj.FeedbackType && (
              <div className="info">
                <span>Please provide any additional information.</span>
                <textarea
                  className={`${
                    feedbackObj.AdditionalInformation.length > 500
                      ? "txt-error notranslate"
                      : "notranslate"
                  }`}
                  name="AdditionalInformation"
                  onChange={handleFieldChange}
                  value={feedbackObj.AdditionalInformation}
                ></textarea>
                <span className="char-count notranslate">
                  {feedbackObj.AdditionalInformation.length}/500
                </span>
              </div>
            )}
            {APP_CONFIG.isTransferee && feedbackObj.FeedbackType && (
              <div className="contact-confirm">
                <span className="confirm-text">
                  Would you like us to contact you directly?
                </span>
                <div className="confirm-feedback">
                  <div className="label-container">
                    <label className="container" htmlFor="contact-yes">
                      <input
                        type="radio"
                        id="contact-yes"
                        name="can_contact"
                        value={1}
                        onChange={handleCanContactChange}
                      />
                      <span>Yes</span>
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div>
                    <label className="container" htmlFor="contact-no">
                      <input
                        type="radio"
                        id="contact-no"
                        name="can_contact"
                        value={0}
                        onChange={handleCanContactChange}
                      />
                      <span>No</span>
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            )}
            {!!canContact && (
              <div className="preferred-contact-wrapper">
                <span>Preferred Method of Contact</span>
                <div className="preferred-contact">
                  <div>
                    <label htmlFor="chkEmail" className="custom-checkbox">
                      <input
                        type="checkbox"
                        name="ContactEmail"
                        id="chkEmail"
                        checked={contactMethod.ContactEmail}
                        onChange={handleContactMethodChange}
                      />
                      <span className="checkmark"></span>
                      <span className="chk-label">Email</span>
                      <input
                        autoFocus
                        type="text"
                        name="ContactEmail"
                        className={`${error.ContactEmail ? "input-err" : ""}`}
                        placeholder="Email"
                        onChange={handleFieldChange}
                        value={feedbackObj.ContactEmail}
                      />
                    </label>
                    {error.ContactEmail && (
                      <span className="error-msg">* Invalid email address</span>
                    )}
                  </div>
                  <div className="mt-25">
                    <label htmlFor="chkPhone" className="custom-checkbox">
                      <input
                        type="checkbox"
                        id="chkPhone"
                        name="ContactPhoneNumber"
                        checked={contactMethod.ContactPhoneNumber}
                        onChange={handleContactMethodChange}
                      />
                      <span className="chk-label">Phone</span>
                      <span className="checkmark"></span>
                      <input
                        type="text"
                        name="ContactPhoneNumber"
                        className={`${
                          error.ContactPhoneNumber ? "input-err" : ""
                        }`}
                        placeholder="Phone Number"
                        onChange={handleFieldChange}
                        value={feedbackObj.ContactPhoneNumber}
                      />
                    </label>
                    {error.ContactPhoneNumber && (
                      <span className="error-msg">* Invalid phone number</span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="footer-btns">
            <span className="cancel-btn" onClick={onClose}>
              Cancel
            </span>
            <button
              disabled={preventSubmit}
              className="save-btn"
              onClick={handleFeedbackSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const RatingScale = ({ feedbackObj, onChange }) => {
  const renderText = () => {
    let text = "";
    switch (feedbackObj.FeedbackType) {
      case 1:
        text = "Overall how satisfied are you with content on this page?";
        break;
      case 2:
        text =
          "Overall how satisfied are you with service you have been provided so far?";
        break;
      case 3:
        text =
          "Overall how satisfied are you with the site navigation and its functionality?";
        break;
      default:
        text = "";
    }
    return text;
  };
  return (
    <div className="rating-scale">
      <span className="overall-satisfied-txt">{renderText()}</span>
      <div className="rating-number notranslate">
        {[...Array(10).keys()].map((point) => (
          <button
            name="Rating"
            key={`${point}-${new Date().getMilliseconds().toString()}`}
            className={`notranslate 
            ${feedbackObj.Rating === point + 1 ? "active" : ""}`}
            value={point + 1}
            onClick={onChange}
          >
            {point + 1}
          </button>
        ))}
      </div>
      <div className="completely-satisfied-wrapper">
        <span>
          Completely <br />
          Dissatisfied
        </span>
        <span>
          Completely <br />
          Satisfied
        </span>
      </div>
    </div>
  );
};
