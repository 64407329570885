import "core-js";
import "../require-babel-polyfill";
import axios from "axios";

const APP_CONFIG = window.globalObject || {};

const API = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Pragma: "no-cache",
    "Cache-Control": "no-store",
    RequestVerificationToken: document.getElementById(
      "__requestVerificationToken"
    ).value,
  },
});

export const GoogleService = axios.create({
  baseURL: "https://maps.googleapis.com",
});
export default API;

export const umbracoApi = axios.create({
  baseURL: APP_CONFIG.umbracoBaseUrl,
  headers: {
    Accept: "application/json",
    "Accept-Language": "en-us",
    "Api-Version": 2.1,
    "Api-Key": APP_CONFIG.umbracoKey,
    "Umb-Project-Alias": APP_CONFIG.umbracoProjectAlias,
  },
});
